import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, Flex, Text, useMediaQuery, Image, VStack } from '@chakra-ui/react';




//import Logo from '../public/logo_white.png';
function App() {

  const [timeRemaining, setTimeRemaining] = useState({});
const [isLargerThanMD] = useMediaQuery("(min-width: 768px)"); 
  const flexDirection = isLargerThanMD ? "row" : "column";

  useEffect(() => {
    // Set up the interval to update every second
    const interval = setInterval(() => {
      // Current date
      const now = new Date();

      // Target date: September 15, 2023
      const targetDate = new Date(2023, 8, 15);
      
      // Calculate the difference in milliseconds
      const difference = targetDate - now;

      // Convert the difference to days, hours, minutes, and seconds
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeRemaining({ days, hours, minutes, seconds });

    }, 1000);    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);


  return (
    <ChakraProvider>
<Flex
      w="100vw"
      h="100vh"
      direction={flexDirection}
    >
      <Box
        flex="1"
        bgColor="white"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
       <Image src={process.env.PUBLIC_URL + '/logo_white.png'} alt="Logo"/>
      </Box>
      <Box
        flex="1"
        bgColor="black"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <VStack>
        <Text fontSize="4rem" color="white">En consrtucción</Text>
        <Text fontSize="2rem" color="gray">Faltan</Text>
        <Text fontSize="2rem" color="red">{timeRemaining.days} días</Text>
        <Text fontSize="2rem" color="tomato">{timeRemaining.hours} horas</Text>
        <Text fontSize="2rem" color="blue">{timeRemaining.minutes} minutos</Text>
        <Text fontSize="2rem" color="green">{timeRemaining.seconds} Segundos</Text>
        </VStack>
      </Box>
    </Flex>
    </ChakraProvider>
  )
}

export default App;
